<template>
  <section class="section-banner-principal">
    <div
      class="img-decoration-bg"
      :style="{'background-image': `url(${require('@/assets/bg-quac-principal.svg')})`}"
    />
    <div class="conatiner-info-banner">
      <h1>
        Accede al crédito <span>QUAC:</span> Rápido, fácil y para todos
      </h1>
      <p>
        Obtén el financiamiento que necesitas para tus compras en línea y en tiendas físicas
      </p>
      <button class="buton buton-primary" @click="navigateToPreaprobado()">Estoy interesado</button>
    </div>
    <div class="container-img-animated">
      <img class="img-main-banner img-main-banner-1" src="@/assets/img-banner-principal-1.png" alt="iamgen portada quac">
      <img class="img-main-banner img-main-banner-2" src="@/assets/img-banner-principal-3.png" alt="iamgen portada quac">
      <img class="img-main-banner img-main-banner-3" src="@/assets/img-banner-principal-2.png" alt="iamgen portada quac">
      <img class="float-img float-img-1" src="@/assets/img-float-1.png">
      <img class="float-img float-img-2" src="@/assets/img-float-2.png">
      <img class="float-img float-img-3" src="@/assets/img-float-3.png">
      <img class="float-img float-img-4" src="@/assets/img-float-4.png">
      <img class="float-img float-img-5" src="@/assets/img-float-5.png">
    </div>
  </section>
</template>
<script>
export default {
  mounted() {
    if(window.innerWidth < 768) {
      document.addEventListener('scroll', () => {
        var element = document.getElementById('interested');
        var element1 = document.getElementById('interested-fixed');
        if(window.scrollY > (element.offsetTop + 84) && window.innerWidth < 768) {
          element.style.opacity = "0";
          element1.style.display = "block";
        } else {
          element.style.opacity = "1";
          element1.style.display = "none";
        }
      })
    }
  },
  methods: {
    navigateToPreaprobado() {
      location.href = 'https://preaprobado.quac.co/';
    }
  }
}
</script>
<style lang="scss" scoped>
  .section-banner-principal {
    background: var(--bgColor);
    min-height: 573px;
    display: flex;
    flex-wrap: wrap;
    gap: 5%;
    justify-content: center;
    align-items: stretch;
    padding-left: 10%;
    padding-right: 10%;
    overflow: hidden;
    position: relative;
    margin-bottom: 55px;
    .img-decoration-bg {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      max-height: 650px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: right;
      background-color: var(--bgColor)
    }
    .conatiner-info-banner {
      width: 57.5%;
      max-width: 520px;
      display: flex;
      flex-wrap: wrap;
      align-content: center;
      position: relative;
      h1 {
        color: var(--colorTitlePrimary);
        font-weight: bold;
        margin-bottom: 2rem;
        width: 100%;
        span {
          font-size: inherit;
          font-family: inherit;
          color: var(--secundaryColor);
        }
      }
      p {
        color: var(--primaryColorDark);
        margin-bottom: 2rem;
        width: 100%;
      }
    }
    .container-img-animated {
      width: 37.5%;
      max-width: 450px;
      display: flex;
      position: relative;
      align-content: flex-end;
      .img-main-banner {
        margin-top: auto;
        position: relative;
        z-index: 2;
        width: 100%;
        opacity: 0;
        aspect-ratio: 431/517;
        mask-image: linear-gradient(var(--bgColor) 80%, transparent);
        &-1 {
          animation: fadeImageBanner 15s ease-in-out infinite;
        }
        &-2 {
          animation: fadeImageBanner 15s ease-in-out infinite;
          animation-delay: 5s;
        }
        &-3 {
          animation: fadeImageBanner 15s ease-in-out infinite;
          animation-delay: 10s;
        }
      }
      .float-img {
        position: absolute;
        &-1{
          position: absolute;
          top: 35%;
          left: -20px;
          width: 87px;
          animation: animationUpDown 2.5s ease-in-out infinite alternate;
          z-index: 2;
        }
        &-2{
          position: absolute;
          bottom: 31%;
          left: -33px;
          width: 59px;
          animation: animationUpDown 2s ease-in-out infinite alternate;
          z-index: 2;
        }
        &-3{
          position: absolute;
          bottom: 10%;
          left: -3px;
          width: 98px;
          animation: animationUpDown 2.7s ease-in-out infinite alternate;
          z-index: 2;
        }
        &-4{
          position: absolute;
          bottom: 55%;
          left: 75%;
          width: 131px;
          animation: animationUpDown 2.7s ease-in-out infinite alternate;
          z-index: 2;
        }
        &-5{
          position: absolute;
          bottom: 27%;
          left: 74%;
          width: 78px;
          animation: animationUpDown 2.1s ease-in-out infinite alternate;
          z-index: 2;
        }
      }
    }
    @media (max-width: 800px) {
      .conatiner-info-banner {
        width: 100%;
        padding-top: 2rem;
      }
      .container-img-animated {
        width: 90%;
        max-width: 360px;
        z-index: 2;
        padding-top: 2rem;
        .float-img {
          position: absolute;
          &-1{
            width: 72px;
            z-index: 2;
          }
          &-2{
            width: 58px;
          }
          &-3{
            width: 62px;
            z-index: 2;
          }
          &-4{
            width: 67px;
            z-index: 2;
          }
          &-5{
            width: 64px;
          }
          &-6{
            width: 50px;
          }
        }
      }
    }
    @media (max-width: 768px) {
      padding-top: 0;
      padding-right: 0;
      position: relative;
      z-index: 10;
      .conatiner-info-banner {
        h1 {
          font-size: 30px;
        }
        .btn-primary {
          width: 100%;
        }
      }
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 120px;
        background-color: white;
        z-index: 0;
      }
      .conatiner-info-banner {
        width: 100%;
        margin-top: 100px;
        z-index: 2;
        margin-bottom: 2rem;
      }
      .container-img-animated {
        width: 90%;
        max-width: 360px;
        z-index: 2;
        .float-img {
          position: absolute;
          &-1{
            width: 72px;
            z-index: 2;
          }
          &-2{
            width: 58px;
          }
          &-3{
            bottom: 52%;
            width: 62px;
            z-index: 2;
          }
          &-4{
            width: 67px;
            z-index: 2;
            bottom: 66%;
          }
          &-5{
            width: 64px;
            bottom: 85%;
          }
          &-6{
            width: 50px;
            bottom: 43%;
          }
        }
      }
    }
  }
</style>
